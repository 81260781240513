import axios from "@/api/baseRequest.js";

//根据条件列出页脚
export const apiListFooterByCondition = (token, formData) =>
    axios.post("/api/v1/admin/footer/list_by_condition", formData, {
        headers: {
            "token": token
        }
    });

//增加页脚
export const apiAddFooter = (token, formData) =>
    axios.post("/api/v1/admin/footer/add", formData, {
        headers: {
            "token": token
        }
    });

//根据ID获取页脚
export const apiGetFooterById = function (token, id) {
    return axios.get("/api/v1/admin/footer/get_by_id", {
        headers: {
            "token": token
        },
        params: {
            "id": id
        }
    });
}

//更新页脚
export const apiUpdateFooter = (token, formData) =>
    axios.post("/api/v1/admin/footer/update", formData, {
        headers: {
            "token": token
        }
    });

//删除页脚
export const apiDeleteFooter = (token, formData) =>
    axios.post("/api/v1/admin/footer/delete_by_id", formData, {
        headers: {
            "token": token
        }
    });